import React, { useEffect, useState } from "react";
import Rightcontainer from "../Components/Rightcontainer";

const Support = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;

  var baseUrl;
  if (nodeMode === "development") {
    baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl
  }

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    // <div>
    //   <div className="leftContainer" style={{ minHeight: '100vh', height: '100%' }}>



    //     <div className="cxy flex-column " style={{ paddingTop: "16%" }}>
    //       <img src={process.env.PUBLIC_URL + '/Images/contact_us.png'} width="280px" alt="" />
    //       <div className="games-section-title mt-4" style={{ fontSize: "1.2em", fontWeight: '700', color: '2c2c2c' }}>
    //         Contact us at below platforms.
    //       </div>

    //       <div className="row justify-content-center">
    //       <div className="col-4  d-flex justify-content-around w-80">
    //       <a className="cxy flex-column" href={(WebSitesettings.telegram) ? "https://t.me/"+WebSitesettings.telegram : ''}>
    //         <img width="50px" src={process.env.PUBLIC_URL + '/Images/tel.png'} alt="" />
    //         <span className="footer-text-bold">{(WebSitesettings.telegram) ? WebSitesettings.telegram : ''}</span>
    //       </a>
    //     </div>
    //       </div>


    //      <div className="row justify-content-center">
    //       <div className="col-4  d-flex justify-content-around w-80">
    //           <a className="cxy flex-column" href={(WebSitesettings.instragram) ? 'https://www.instagram.com/'+WebSitesettings.instragram+'/' : '/'}>
    //           <img width="50px" src={process.env.PUBLIC_URL + '/Images/instagram.png'} alt="" />
    //           <span className="footer-text-bold">{(WebSitesettings.instragram) ? WebSitesettings.instragram : ''}</span>

    //           </a>
    //         </div>
    //       </div>

    //       <div className="col-12 my-2 text-center font-weight-bold">
    //       <a className="cxy flex-column" href={(WebSitesettings.whatsapp) ? "https://api.whatsapp.com/send?phone="+WebSitesettings.whatsapp+"&text=Hello" : ''}>
    //           <img width="50px" src={process.env.PUBLIC_URL + '/Images/whatsapp.png'} alt="" />
    //           <span className="footer-text-bold">{(WebSitesettings.whatsapp) ? WebSitesettings.whatsapp : ''}</span>

    //         </a>
    //       </div>

    //       <div className="col-12 my-2 text-center font-weight-bold">
    //         <a className="cxy flex-column" href={(WebSitesettings.CompanyEmail) ? 'mailto:'+WebSitesettings.CompanyEmail : ''}>
    //           <img width="50px" src={process.env.PUBLIC_URL + '/Images/mail.png'} alt="" />
    //           <span className="footer-text-bold">{(WebSitesettings.CompanyEmail) ? WebSitesettings.CompanyEmail : ''}</span>
    //         </a>
    //       </div>

    //       <div className="col-12 my-2 text-center font-weight-bold">
    //         <a className="cxy flex-column" href="#">
    //         <span className="footer-text-bold"><a href={(WebSitesettings.CompanyMobile) ? 'tel:'+WebSitesettings.CompanyMobile : ''}>{(WebSitesettings.CompanyMobile) ? WebSitesettings.CompanyMobile : ''}</a></span>
    //           <span className="footer-text-bold">{(WebSitesettings) ? WebSitesettings.CompanyName : ''}</span>
    //           <span className="footer-text-bold">
    //           {(WebSitesettings) ? WebSitesettings.CompanyAddress : ''}</span>
    //         </a>
    //       </div>

    //     </div>
    //   </div>
    //   <div className="rightContainer">
    //     <Rightcontainer />
    //   </div>
    // </div>
    <>
      <div>
        <div
          className="leftContainer mb_space"
          style={{ minHeight: "100vh", height: "100%" }}
        >
          <div className="pt-5 mt-5  Orher_page_main_section">
            <div className=" ">
              <div className="profile_headings mt-4">
                Contact us at below platforms.
              </div>
              <div className="row mt-3">



                {/* whatsapp */}
                {/*
                <div className="col-6 col-lg-4 col-md-3 col-sm-12 mb-3">
                  <div className="support_samll_card_btn_type">
                    <a href="#">
                      <img
                        width="50px"
                        src="/whatsapp.png"
                        alt="WhatsApp Logo"
                      />
                      <span>WhatsApp</span>
                    </a>

                  </div>
                </div>
                */}
                {/* Telegram */}
                
                <div className="col-6 col-lg-4 col-md-3 col-sm-12 mb-3">
                  <div className="support_samll_card_btn_type">
                    <a href="https://wa.me/+919772900999?text=hello%20admin%20i%20need%20help%20%3F%20">
                      <img
                        width="50px"
                        src={process.env.PUBLIC_URL + '/whatsapp.png'}
                        alt="WhatsApp Logo"
                      />
                      <span>WhatsApp</span>
                    </a>

                  </div>
                </div>
                
                
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                  <p className="company_address">
                    Address:
                    {/* <span>
                    {JSON.parse(localStorage.getItem("sitSetting")) ==
                    "undefined"
                      ? ""
                      : JSON.parse(localStorage.getItem("sitSetting"))
                          ?.CompanyAddress}
                  </span> */} JAIPUR RAJATSHAN                </p>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div className="rightContainer">
          <Rightcontainer />
        </div>
      </div>
    </>
  );
};

export default Support;